var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col-lg-12 col-md-12 col-sm-12" },
      [
        _c(
          "c-table",
          {
            ref: "vendorTable",
            attrs: {
              title: "수급업체 첨부파일 목록",
              columns: _vm.grid.columns,
              data: _vm.grid.data,
              filtering: false,
              isFullScreen: false,
              columnSetting: false,
              hideBottom: "",
              selection: "multiple",
              rowKey: "mdmVendorAttachId",
            },
          },
          [
            _c(
              "template",
              { slot: "table-button" },
              [
                _c(
                  "q-btn-group",
                  { attrs: { outline: "" } },
                  [
                    _vm.editable && this.parentVendorCd
                      ? _c("c-btn", {
                          attrs: { label: "추가", icon: "add" },
                          on: { btnClicked: _vm.addAttach },
                        })
                      : _vm._e(),
                    _vm.editable && this.parentVendorCd
                      ? _c("c-btn", {
                          attrs: { label: "삭제", icon: "remove" },
                          on: { btnClicked: _vm.deleteAttach },
                        })
                      : _vm._e(),
                    _vm.editable && this.parentVendorCd
                      ? _c("c-btn", {
                          attrs: {
                            isSubmit: _vm.isSaveAttach,
                            url: _vm.saveAttachUrl,
                            param: _vm.grid.data,
                            mappingType: "PUT",
                            label: "저장",
                            icon: "save",
                          },
                          on: {
                            beforeAction: _vm.saveAttach,
                            btnCallback: _vm.saveAttachCallback,
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          2
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }